import React from "react";
import Seo from "../../components/SEO/SEO";
import Layout from "../../components/Layout/Layout";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
// import StopIcon from "@material-ui/icons/Stop";
import ButtonBackToHome from "../../components/Button/ButtonBackToHome";
import { StaticImage } from "gatsby-plugin-image";

const GuidePage = () => {
  const breakpointsXs = useMediaQuery("(max-width: 1000px)");

  return (
    <Layout>
      <Seo title="Hướng dẫn" />
      <Box bgcolor="#f5f5f5">
        <Box
          width={breakpointsXs ? "90%" : "80%"}
          margin="auto"
          py={breakpointsXs ? 3 : 7}
        >
          <Box
            display={breakpointsXs ? undefined : "flex"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">
              <b>HƯỚNG DẪN THANH TOÁN</b>
            </Typography>
            <Box my={2}>
              <ButtonBackToHome />
            </Box>
          </Box>
          {/* <Box mt={3}>
            <Box display="flex" mt={0.5}>
              <Box color="#308632" mr={0.5}>
                <StopIcon fontSize="small" />
              </Box>
              <Box fontSize={20}>Hướng dẫn thanh toán</Box>
            </Box>
            <Box display="flex" mt={2}>
              <Box color="#308632" mr={0.5}>
                <StopIcon fontSize="small" />
              </Box>
              <Box fontSize={20}>Quy trình đặt hàng</Box>
            </Box>
            <Box display="flex" mt={2}>
              <Box color="#308632" mr={0.5}>
                <StopIcon fontSize="small" />
              </Box>
              <Box fontSize={20}>Phướng thức giao hàng</Box>
            </Box>
          </Box> */}
        </Box>
      </Box>
      <Box
        width={breakpointsXs ? "90%" : "80%"}
        margin="auto"
        py={breakpointsXs ? 3 : 7}
      >
        {/* <Box fontSize={30}>
          <b>Hướng Dẫn Thanh Toán</b>
        </Box> */}
        <Box
          display={breakpointsXs ? undefined : "flex"}
          justifyContent="space-between"
          mt={3}
        >
          <Box width={breakpointsXs ? "100%" : "48%"}>
            <Box width="100%">
              <StaticImage src="../../images/HUONGDAN.png" alt="" />
            </Box>
            <Box fontSize={26} mt={1.5} mb={1}>
              <b>Thanh Toán Trực Tiếp Bằng Tiền Mặt</b>
            </Box>
            <Typography variant="caption">
              Quý khách hàng thanh toán trực tiếp tại phòng{" "}
              <b>
                <i>tài chính - kế toán</i>
              </b>{" "}
              công ty trách nhiệm hữu hạn 2-9.
              <br />
              Địa chỉ: Tổ dân phố Ngô Gia Khảm, P. Châu Sơn, TP. Phủ Lý, Hà Nam
            </Typography>
          </Box>
          <Box width={breakpointsXs ? "100%" : "48%"}>
            <Box fontSize={26} my={3}>
              <b>Thanh Toán Chuyển Khoản</b>
            </Box>
            <Typography variant="caption">
              Quý khách hàng thanh toán chuyển khoản cho công ty trách nhiệm hữu
              hạn 2-9 vào các tài khoản dưới đây:
              <br />
              <b>a. Tài khoản công ty:</b>
              <Box>Tên tài khoản: Công ty trách nhiệm hữu hạn 2-9</Box>
              <Box display="flex">
                <Box minWidth={120}>Số tài khoản:</Box>
                <Box>
                  1077040449568 tại Ngân hàng PG bank CN Thăng Long, Hà Nội
                  <br />
                  231563668 tại Ngân hàng VP Bank CN Hà Nam
                  <br />
                  48210000006108 tại Ngân hàng BIDV Hà Nam
                </Box>
              </Box>
              <b>b. Tài khoản cá nhân:</b>
              <br />
              <Box>Tên tài khoản: Nguyễn Trang Dung</Box>
              <Box>
                Số tài khoản: 7700108671006 tại Ngân hàng MBBank - CN Hà Nam
              </Box>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default GuidePage;
